import React from 'react';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ResetCSS } from '../common/assets/css/style';
import ErrorImage from '../common/assets/image/404.svg';

const NotFoundPage = () => (
    <>
        <SEO title="404: Not found" />
        <ResetCSS />
        <ErrorSec
            title="Page not found!"
            imageUrl={ErrorImage}
            imageTitle="404"
            text="Looks like the page you're trying to visit dosen't exist. Please check the URL and try your luck again."
        />
    </>
);

export default NotFoundPage;
